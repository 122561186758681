import { ConversationResponse, Message } from "../components/models/interfaces";

export const getCompletion = async (user_message: string, messages: Message[], endpoint: string) => {
    const response = await fetch(process.env.REACT_APP_ENDPOINT + endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            message: user_message,
            history: messages,
        }),
    });
    return response;
}

export const createErrorMessage = (message: string) => {
    const botMessageError: Message = {
        content: message,
        role: "assistant"
    };
    return botMessageError
}

export const generateResponse = async (user_message: string, history: Message[], endpoint: string) => {
    try {
        const response = await getCompletion(user_message, history, endpoint);

        if (response.ok) {
            const resp = await response.text();
            const data: ConversationResponse = JSON.parse(resp)
            const botMessage: Message = data.response
            return { response: botMessage, history: data.history };
        } else {
            return { response: createErrorMessage("Couldn't communicate with server"), history: history } ;
        }
    } catch (error) {
        return { response: createErrorMessage("Couldn't communicate with server"), history: history };
    }
}