import React, { useState } from 'react';
import './App.css';
import Chatbot from './components/Chatbot';
import LoginPage from './components/LoginPage';
import SelectorPage from './components/SelectorPage';
import { ChatbotConfig, ChatbotType, Pages } from './components/models/interfaces';
import { CHATBOT_CONFIGS } from './components/models/config';


const App: React.FC = () => {

  const [activePage, setActivePage] = useState<Pages>("login");
  const [selectedChatbotConfig, setSelectedChatbotConfig] = useState<ChatbotConfig>(CHATBOT_CONFIGS[0])

  const handleLogin = () => {
    setActivePage("selector")
  }

  const handleBack = () => {
    setActivePage("selector")
  }

  const handleSelection = (selectedChatbot : ChatbotType) => {
    setSelectedChatbotConfig(CHATBOT_CONFIGS.find(config => config.id === selectedChatbot) || CHATBOT_CONFIGS[0])
    setActivePage("chatbot")
  }

  return (
    <div className="app-container">
      {activePage === "login" && (<LoginPage handleLogin={handleLogin} />)}
      {activePage === "selector" && (<SelectorPage handleSelection={handleSelection}/>)}
      {activePage === "chatbot" && (<Chatbot chatbotConfig={selectedChatbotConfig} handleBack={handleBack}/>)}
    </div>
  );
}

export default App;
