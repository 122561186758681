import React, { useState } from 'react';

import styles from "./LoginPage.module.css"
import { LoginPageProps } from '../models/props';

const LoginPage: React.FC<LoginPageProps> = (props) => {
    
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [isWrongCredentials, setIsWrongCredentials] = useState<boolean>(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "username") {
            setUsername(event.target.value)
        }

        if (event.target.name === "password") {
            setPassword(event.target.value)
        }
    };

    const handleLogin = () => {
        if (username === "key" && password === "biz") {
            setIsWrongCredentials(false);
            props.handleLogin()
        }
        else {
            setIsWrongCredentials(true);
        }
    }

    return (
        <div className={styles.loginContainer}>
            <div className={styles.loginForm}>
                <div className={styles.loginHeader}>
                    <h2>Welcome back!</h2>
                    <div className={styles.loginDescription}>
                        Enter yours credentials to access your account
                    </div>
                    {isWrongCredentials && (
                        <div className={styles.wrongCredentials}>
                            Credentials are wrong.
                        </div>
                    )}
                </div>
                <div className={styles.loginInputs}>
                    <input
                        type="text"
                        name='username'
                        className={styles.usernameIcon}
                        placeholder="Enter your username"
                        value={username}
                        onChange={handleInputChange}
                    />
                    <input
                        type="password"
                        name='password'
                        className={styles.passwordIcon}
                        placeholder="Enter your password"
                        value={password}
                        onChange={handleInputChange}
                    />
                    <button className={styles.loginButton} onClick={handleLogin}>
                        SIGN IN
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
