// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectorPage_pageContainer__A2R6W {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.SelectorPage_header__zUET2 {
    margin-top: 10px;
}

.SelectorPage_selectorContainer__Jzat- {
    width: 1000px;
    height: 500px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    flex: 1 1;
    padding-bottom: 50px;
    padding-top: 50px;
}

.SelectorPage_chatbotButton__2wXFX {
    width: 300px;
    height: 120px;
    border-radius: 25px;
    box-shadow: 0px 4px 15px 0px #00000040;
    padding: 40px;
    cursor: pointer;
    transition: all 0.2s 0s ease-in-out;
}

.SelectorPage_chatbotButton__2wXFX:hover {
    background-color: white;
}

.SelectorPage_chatbotHeader__1eytj {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.SelectorPage_chatbotLogo__v9uhc { 
    margin-right: 10px;
    color: #3E3C61;
}

.SelectorPage_chatbotName__WmSFP {
    font-size: 26px;
    color: #3E3C61;
}

.SelectorPage_chatbotDescription__e\\+Yth {
    font-size: 14px;   
    text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/components/SelectorPage/SelectorPage.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,6BAA6B;IAC7B,mBAAmB;IACnB,SAAS;IACT,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sCAAsC;IACtC,aAAa;IACb,eAAe;IACf,mCAAmC;AACvC;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".pageContainer {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n}\n\n.header {\n    margin-top: 10px;\n}\n\n.selectorContainer {\n    width: 1000px;\n    height: 500px;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-around;\n    align-items: center;\n    flex: 1 1;\n    padding-bottom: 50px;\n    padding-top: 50px;\n}\n\n.chatbotButton {\n    width: 300px;\n    height: 120px;\n    border-radius: 25px;\n    box-shadow: 0px 4px 15px 0px #00000040;\n    padding: 40px;\n    cursor: pointer;\n    transition: all 0.2s 0s ease-in-out;\n}\n\n.chatbotButton:hover {\n    background-color: white;\n}\n\n.chatbotHeader {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n.chatbotLogo { \n    margin-right: 10px;\n    color: #3E3C61;\n}\n\n.chatbotName {\n    font-size: 26px;\n    color: #3E3C61;\n}\n\n.chatbotDescription {\n    font-size: 14px;   \n    text-align: justify;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": `SelectorPage_pageContainer__A2R6W`,
	"header": `SelectorPage_header__zUET2`,
	"selectorContainer": `SelectorPage_selectorContainer__Jzat-`,
	"chatbotButton": `SelectorPage_chatbotButton__2wXFX`,
	"chatbotHeader": `SelectorPage_chatbotHeader__1eytj`,
	"chatbotLogo": `SelectorPage_chatbotLogo__v9uhc`,
	"chatbotName": `SelectorPage_chatbotName__WmSFP`,
	"chatbotDescription": `SelectorPage_chatbotDescription__e+Yth`
};
export default ___CSS_LOADER_EXPORT___;
