// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  height: 100%;
}

body {
  background-color: #f7f8fa;
  height: 100%;
  margin: 0;
  font-family: "Montserrat" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: "Montserrat" !important;
}

ul {
  padding-left: 18px !important;
}

/* styles.css */

#root {
  height: 100%;
}

.app-container {
  height: 100%;
  background-color: #f7f8fa;
  padding: 0px;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
}

.app-header {
  height: 50px;
  background-color: #00619e;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,SAAS;EACT,oCAAoC;EACpC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA,eAAe;;AAEf;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B","sourcesContent":["html {\n  height: 100%;\n}\n\nbody {\n  background-color: #f7f8fa;\n  height: 100%;\n  margin: 0;\n  font-family: \"Montserrat\" !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ninput {\n  font-family: \"Montserrat\" !important;\n}\n\nul {\n  padding-left: 18px !important;\n}\n\n/* styles.css */\n\n#root {\n  height: 100%;\n}\n\n.app-container {\n  height: 100%;\n  background-color: #f7f8fa;\n  padding: 0px;\n  display: flex;\n  flex: 1 1 100%;\n  flex-direction: column;\n}\n\n.app-header {\n  height: 50px;\n  background-color: #00619e;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
