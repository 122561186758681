import React, { useState } from 'react';
import styles from "./Chatbot.module.css"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { ReactComponent as SendIcon } from "../../statics/send_icon.svg"
import { ReactComponent as MicIcon } from "../../statics/mic_icon.svg"

import { Conversation } from './Conversation';
import { ChatbotProps } from '../models/props';
import { Message } from '../models/interfaces';
import { generateResponse } from '../../api/completionAPI';

const Chatbot: React.FC<ChatbotProps> = (props) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [history, setHistory] = useState<Message[]>([]);

    const [inputValue, setInputValue] = useState<string>('');
    const [isWaitingResponse, setIsWaitingResponse] = useState<boolean>(false);

    const {
        transcript,
        listening,
        resetTranscript,
        isMicrophoneAvailable,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const startListening = () => {
        resetTranscript()
        console.log("Started listening", isMicrophoneAvailable, browserSupportsSpeechRecognition)
        SpeechRecognition.startListening({ continuous: true })
    }

    const stopListening = () => {
        console.log("Stopped listening")
        console.log("Transcript", transcript)
        setInputValue(transcript)
        SpeechRecognition.stopListening()
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleEnterSendMessage = async (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            handleSendMessage()
        }
    }

    const handleSendMessage = async () => {
        if (inputValue.trim() === '') {
            return;
        }

        const newMessage: Message = {
            content: inputValue,
            role: 'user',
        };

        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInputValue('');
        setIsWaitingResponse(true);

        await generateResponse(newMessage.content, history, props.chatbotConfig?.endpoint).then(async (resp) => {
            setMessages(prevMessages => [...prevMessages, resp.response]);
            setHistory([...resp.history, resp.response]);
            setIsWaitingResponse(false);
        });
    };

    return (
        <div className={styles.chatbotContainer}>
            <div className={styles.header}>
                {props.chatbotConfig?.logo}
                <h1>{props.chatbotConfig?.title}</h1>
                <div className={styles.backButton} onClick={() => { props.handleBack() }}>
                    {"< Back"}
                </div>
            </div>
            <div className={styles.chatbotMessages}>
                <Conversation messages={messages} isWaitingResponse={isWaitingResponse} />
            </div>

            <div className={styles.chatbotInput}>
                <input
                    type="text"
                    placeholder="Inserisci il tuo messaggio..."
                    value={listening ? transcript : inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleEnterSendMessage}
                    disabled={isWaitingResponse}
                />
                <button
                    className={styles.sendButton}
                    disabled={isWaitingResponse}
                    onMouseDown={startListening}
                    onMouseUp={stopListening}
                >
                    <MicIcon width={20} height={20} />
                </button>

                <button className={styles.sendButton} onClick={handleSendMessage} disabled={isWaitingResponse}>
                    <SendIcon width={20} height={20} />
                </button>

                
            </div>
        </div>
    );
};

export default Chatbot;
