import React from 'react';
import styles from "./Chatbot.module.css";

import { AlwaysScrollToBottom } from './AlwaysScrollToBottom';
import { WaitingMessage } from '../atoms/WaitingMessage';
import { ConversationProps } from '../models/props';


export const Conversation: React.FC<ConversationProps> = (props) => {
    return (
        <>
            {props.messages.map((message, index) => (
                <>
                    <div
                        key={index}
                        className={styles.message + " " + (message.role === "user" ? styles.userMessage : styles.botMessage)}
                        dangerouslySetInnerHTML={{ __html:  message.content.replaceAll("\n", "<br />")}} />
                    <AlwaysScrollToBottom />
                </>
            ))}
            {props.isWaitingResponse && (<WaitingMessage />)}
        </>
    );
};
