import { ReactComponent as PrivacyIcon } from "../../statics/privacy_icon.svg"
import { ReactComponent as PolicyIcon } from "../../statics/policy_icon.svg"
import { ReactComponent as HRIcon } from "../../statics/hr_icon.svg"
import { ChatbotConfig } from "./interfaces"

export const CHATBOT_CONFIGS: ChatbotConfig[] = [
    {
        id: "default",
        title: "Generic Chatbot", logo: <PolicyIcon width={30} />,
        description: "Quisque condimentum aliquam nibh, non ornare justo ornare in. Class aptent taciti sociosqu",
        endpoint: "hr_chatbot",
        isVisible: false
    },
    {
        id: "hr_assistant",
        title: "HR Assistant", logo: <HRIcon width={40} />,
        description: "An all-around conversational assistant, able to help with HR policies, skill inventory and team composition",
        endpoint: "hr_chatbot",
        isVisible: true
    },
    {
        id: "privacy_expert",
        title: "Privacy Expert", logo: <PrivacyIcon width={30} />,
        description: "A specialized assistant, that can answer all your questions about how privacy and GDPR is enforced here in Keybiz",
        endpoint: "privacy",
        isVisible: true
    },
    {
        id: "policy_expert",
        title: "Policy Expert", logo: <PolicyIcon width={30} />,
        description: "The policy Expert can help you with all your doubts about company procedures (PTO, maternity leave, TFR, ...)",
        endpoint: "policy",
        isVisible: true
    }
]
