import React from 'react';

import styles from "./SelectorPage.module.css"
import { CHATBOT_CONFIGS } from '../models/config';
import { SelectorPageProps } from '../models/props';

const SelectorPage: React.FC<SelectorPageProps> = (props) => {

    return (
        <div className={styles.pageContainer}>
            <div className={styles.header}>
                <h1>Choose your specialized Conversational Assistant</h1>
            </div>

            <div className={styles.selectorContainer}>
                {CHATBOT_CONFIGS.filter(config => config.isVisible).map(config => (
                    <div className={styles.chatbotButton} onClick={() => { props.handleSelection(config.id) }}>
                        <div className={styles.chatbotHeader}>
                            <div className={styles.chatbotLogo}>
                                {config.logo}
                            </div>
                            <div className={styles.chatbotName}>
                                {config.title}
                            </div>
                        </div>
                        <div className={styles.chatbotDescription}>
                            {config.description}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SelectorPage;
